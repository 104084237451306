import React from "react"
import Image from "../../components/Image"

export default function TeaserFront() {
  return (
    <>
      <Image />
    </>
  )
}
